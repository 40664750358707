
/**
 * @name: 分销管理-提成规则
 * @author: itmobai
 * @date: 2023-06-02 14:42
 * @description：分销管理-提成规则
 * @update: 2023-06-02 14:42
 */
import {Component, Vue} from "vue-property-decorator";
import {IRule} from "@/apis/distributionManage/commissionRule/types";
import {commissionruleDetailApi, commissionruleNewChangeApi} from "@/apis/distributionManage/commissionRule";

@Component({})
export default class DistributionRulePage extends Vue {
  activeName: string = "first"
  // 达人规则
  expertForm: Partial<IRule> = {}
  // 教练
  coachForm: Partial<IRule> = {}
  // 选择日期
  dateArr: any = [
    {
      label: '1',
      value: '1'
    },
    {
      label: '2',
      value: '2'
    },
    {
      label: '3',
      value: '3'
    },
    {
      label: '4',
      value: '4'
    },
    {
      label: '5',
      value: '5'
    },
    {
      label: '6',
      value: '6'
    },
    {
      label: '7',
      value: '7'
    },
    {
      label: '8',
      value: '8'
    },
    {
      label: '9',
      value: '9'
    },
    {
      label: '10',
      value: '10'
    },
    {
      label: '11',
      value: '11'
    },
    {
      label: '12',
      value: '12'
    },
    {
      label: '13',
      value: '13'
    },
    {
      label: '14',
      value: '14'
    },
    {
      label: '15',
      value: '15'
    },
    {
      label: '16',
      value: '16'
    },
    {
      label: '17',
      value: '17'
    },
    {
      label: '18',
      value: '18'
    },
    {
      label: '19',
      value: '19'
    },
    {
      label: '20',
      value: '20'
    },
    {
      label: '21',
      value: '21'
    },
    {
      label: '22',
      value: '22'
    },
    {
      label: '23',
      value: '23'
    },
    {
      label: '24',
      value: '24'
    },
    {
      label: '25',
      value: '25'
    },
    {
      label: '26',
      value: '26'
    },
    {
      label: '27',
      value: '27'
    },
    {
      label: '28',
      value: '28'
    },
    {
      label: '最后一天',
      value: '-1'
    }
  ]

  getDetail () {
    commissionruleDetailApi(this.activeName === 'first' ? 1 : 3).then(e => {
      if (e) {
        if (this.activeName === 'first') {
          this.expertForm = e || {};
        } else {
          this.coachForm = e || {};
        }
      }
    })
  }

  updateExpertData (key: string) {
    let obj: Partial<IRule> = {}
    let data: Partial<IRule> = {}
    if (this.activeName === 'first') {
      obj = this.expertForm
      obj.types = 1
      data.types = 1
    } else {
      obj = this.coachForm
      obj.types = 3
      data.types = 3
    }
    if (obj.id) {
      data.id = obj.id
    }
    if (key === 'grantTime') {
      if (!obj.grantTime) {
        return this.$message.error("请选择发放时间")
      } else {
        data.grantTime = obj.grantTime
      }
    }
    if (key === 'price') {
      if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(String(obj.price))) {
        return this.$message.error("请输入合理的金额")
      } else {
        data.price = obj.price
      }
    }
    if (key === 'belowCommission') {
      if (!obj.belowCommission || (parseFloat(obj.belowCommission) > 100 || parseFloat(obj.belowCommission) < 0)) {
        return this.$message.error("请输入合理的百分比")
      } else {
        data.belowCommission = obj.belowCommission
      }
    }
    if (key === 'higherCommission') {
      if (!obj.higherCommission || (parseFloat(obj.higherCommission) > 100 || parseFloat(obj.higherCommission) < 0)) {
        return this.$message.error("请输入合理的百分比")
      } else {
        data.higherCommission = obj.higherCommission
      }
    }
    commissionruleNewChangeApi(data as IRule).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getDetail()
      }
    })
  }

  handleClick (tab: number) {
    this.getDetail()
  }

  created () {
    this.getDetail()
  }
}
