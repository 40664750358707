/**
 * @name: 分销管理-提成规则接口
 * @author: itmobai
 * @date: 2023-06-02 14:37
 * @description：分销管理-提成规则接口
 * @update: 2023-06-02 14:37
 */
import {get, postJ} from "@/request";
import {IRule} from "../commissionRule/types";

/**
 * 提成规则查询详情
 * @param types
 */
export const commissionruleDetailApi = (types: number) => get<IRule>("/golf/commissionrule/detail/" + types)
/**
 * 提成规则创建/修改
 * @param data
 */
export const commissionruleNewChangeApi = (data: IRule) => postJ("/golf/commissionrule/newChange", data)
